import * as React from "react";
import { Alert, AlertColor, AlertTitle, Stack } from "@mui/material";
import AssetsDataGridPro from "../../../../components/shared/assetsDataGridPro/assetsDataGridPro";
import { useGetAssetsByImKeyQuery } from "../../../../services/cclTokenedAssetsApi";
import ComponentLoader from "../../../../components/common/componentLoader";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import { FileDownloadService } from "../../../../services/fileDownloadService/fileDownloadService";
import FileUploadDialog from "../../../resources/resourceDetail/files/upload/fileUploadDialog";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface UserFilesPanelProps {
    email: string;
    imKey: number;
    readonly?: boolean;
    fullName?: string;
}

const UserFilesPanel: React.FC<UserFilesPanelProps> = (props) => {
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
    const [uploadDialogOpen, setUploadDialogsOpen] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const { logEvent } = useLogAccessEvent();

    const downloadService = new FileDownloadService();
    const {
        data: assets,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetAssetsByImKeyQuery(props.imKey, { skip: !props.imKey });

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    const handleEvents = (evt: string, ids: number[]) => {
        switch (evt) {
            case "download":
                if (ids === undefined || ids.length <= 0) {
                    ShowSnackbarElement(
                        "You must select at least one item to download.",
                        "warning"
                    );
                    return;
                }

                setShowSpinner(true);

                let filename = props.fullName ? `${props.fullName}Files.zip` : "Files.zip";
                if (ids.length === 1) {
                    let asset = assets?.find((a) => a.id === ids[0]);
                    if (asset) {
                        filename = asset.name;
                    }
                }

                downloadService
                    .DownloadAssets({
                        fname: filename,
                        aikeys: ids,
                        flatzip: true,
                    })
                    .then(() => {
                        const evtData: AccessEventIndividualDetails = {
                            imKey: props.imKey.toString(),
                            email: props.email,
                        };
                        logEvent("IndividualFilesDownloaded", evtData);
                        setShowSpinner(false);
                        ShowSnackbarElement(
                            `${ids.length > 0 ? "Files" : "File"} downloaded.`,
                            "success"
                        );
                    })
                    .catch((error) => {
                        setShowSpinner(false);
                        const msg = error.message ?? "Unknown error";
                        ShowSnackbarElement(`Error: ${msg}`, "error");
                    });
                break;

            case "upload":
                setUploadDialogsOpen(true);
                break;

            default:
                break;
        }
    };

    if (isSuccess || isLoading) {
        return (
            <Stack spacing={3}>
                <Alert severity={"warning"}>
                    Please Note: You may automatically lose access to PDF files you have downloaded
                    after 60 days. You can return to CCL Access to download another copy if that
                    happens. We also kindly request you delete any downloaded files within 60 days
                    of downloading them. This is to ensure CCL complies with data security and
                    privacy legislation.
                </Alert>
                {showSpinner ? <ComponentLoader msg={""} /> : null}
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                ) : null}
                <FileUploadDialog
                    email={props.email}
                    imkey={props.imKey}
                    open={uploadDialogOpen}
                    onClose={() => setUploadDialogsOpen(false)}
                />
                <AssetsDataGridPro
                    assets={assets ?? []}
                    isLoading={isLoading}
                    handleEvent={handleEvents}
                    readonly={props.readonly ?? false}
                />
            </Stack>
        );
    }

    if (isLoading) {
        return <ComponentLoader msg={"Loading Files"} />;
    }

    if (isError) {
        return showErrorAlert(
            `Error retrieving files for user key ${props.imKey} (${getErrorMsg(error)})`
        );
    }

    return showErrorAlert(`Unknown error retrieving files for user key ${props.imKey}`);
};
export default UserFilesPanel;
