import * as React from "react";
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import {
    useAddProfileAttributesMutation,
    useUpdateProfileAttributesMutation,
} from "../../../../services/cclTokenedEnterpriseCoachingApi";
import {
    AllAttributeOptions,
    AttributeData,
    ItemLevels,
    Items,
    LanguageType,
    UpdateProfile,
    UpdateProfileType,
} from "../../../../services/types/myAccessTypes";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclUnrestrictedLoadingButton from "../../../../components/common/cclButtons/cclUnrestrictedLoadingButton";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";

interface AddAttributeDrawerProps {
    open: boolean;
    resultHandler: (result: boolean, isAdd: boolean) => void;
    onClose: () => void;
    originalObject: AttributeData[];
    allAttributes: AllAttributeOptions;
    attributeKey: number;
    attributeName: string;
    fromResource: boolean;
    update: boolean;
    currentAttribute: any;
    pkey: string;
    imkey: string;
    email: string;
}

const AddAttributeDrawer: React.FC<AddAttributeDrawerProps> = (props) => {
    const { logEvent } = useLogAccessEvent();

    const [addProfileAttributes, { isLoading: addLoading }] = useAddProfileAttributesMutation();
    const [updateProfileAttribute, { isLoading: updateLoading }] =
        useUpdateProfileAttributesMutation();

    const [availableAttributes, setAvailableAttributes] = React.useState<any[]>([]);
    const [availableAttributeLevels, setAvailableAttributesLevels] = React.useState<any[]>([]);

    //special cases
    const [specialization, setSpecialization] = React.useState<string>("");
    const [initialCertYear, setInitialCertYear] = React.useState<string>("");
    const [certExpiresYear, setCertExpiresYear] = React.useState<string>("");
    const [language, setLanguage] = React.useState<number>(0);

    //selection options
    const [selectedAttribute, setSelectedAttribute] = React.useState<number>();
    const [selectedAttributeLevel, setSelectedAttributeLevel] = React.useState<number>();

    //Values Changed Checks
    const [attributeChanged, setAttributeChanged] = React.useState<boolean>(
        props.update ? false : true
    );
    const [attributeLevelChanged, setAttributeLevelChanged] = React.useState<boolean>(
        props.update ? false : true
    );
    const [languageChanged, setLanguageChanged] = React.useState<boolean>(
        props.update ? false : true
    );

    //set the select options and sort them
    React.useEffect(() => {
        if (!props.allAttributes) {
            return;
        }
        for (let i = 0; i < Object.keys(props.allAttributes.profileItemTypes).length; i++) {
            if (props.allAttributes.profileItemTypes[i].key === props.attributeKey) {
                var tempObj = JSON.parse(JSON.stringify(props.allAttributes.profileItemTypes[i]));
                if (tempObj.key === 2) {
                    var tempLangaugeObj = JSON.parse(JSON.stringify(props.allAttributes.languages));
                    tempLangaugeObj.sort(function (a: LanguageType, be: LanguageType) {
                        return a.name.localeCompare(be.name);
                    });
                    setAvailableAttributes(tempLangaugeObj);
                    return;
                }
                tempObj.itemLevels.sort(function (a: ItemLevels, be: ItemLevels) {
                    return a.name.localeCompare(be.name);
                });
                tempObj.items.sort(function (a: Items, be: Items) {
                    return a.name.localeCompare(be.name);
                });
                setAvailableAttributes(tempObj.items);
                setAvailableAttributesLevels(tempObj.itemLevels);
            }
        }
        //eslint-disable-next-line
    }, [props.allAttributes, props.attributeKey, props.open]);

    //if editing an existing option, set the values to the current ones at default
    React.useEffect(() => {
        if (!props.update || !props.currentAttribute) {
            setAttributeChanged(true);
            setAttributeLevelChanged(true);
            setLanguageChanged(true);
            return;
        }

        setAttributeChanged(false);
        setAttributeLevelChanged(false);
        setLanguageChanged(false);
        if (props.attributeKey === 2) {
            setLanguage(props.currentAttribute[0].language.key);
            return;
        }

        setSelectedAttribute(props.currentAttribute[0].profileItem.key);

        if (props.attributeName === "Professional Certifications") {
            setInitialCertYear(props.currentAttribute[0].initialCertYear);
            setCertExpiresYear(props.currentAttribute[0].certExpiresYear);
            return;
        }

        setSelectedAttributeLevel(props.currentAttribute[0].profileItemTypeLevel.key);

        if (props.attributeName === "Education") {
            setSpecialization(props.currentAttribute[0].specialization);
        }
        //eslint-disable-next-line
    }, [props.currentAttribute, props.update, props.open]);

    const sendLogEvent = (tag: string) => {
        const evtData: AccessEventIndividualDetails = {
            imKey: props.imkey,
            email: props.email,
        };

        logEvent(tag, evtData);
    };

    const addAttributeEvent = () => {
        const tag: string = props.fromResource
            ? "ResourceProfileAttributeChanged"
            : "UserAddedProfileAttribute";
        sendLogEvent(tag);
    };

    const editAttributeEvent = () => {
        const tag: string = props.fromResource
            ? "ResourceProfileAttributeChanged"
            : "UserEditedProfileAttribute";
        sendLogEvent(tag);
    };

    const handleClose = () => {
        setSpecialization("");
        setInitialCertYear("");
        setCertExpiresYear("");
        setLanguage(0);
        setSelectedAttribute(0);
        setSelectedAttributeLevel(0);
        props.onClose();
    };

    const updateAttribute = (value: number) => {
        if (props.update) {
            if (value !== props.currentAttribute[0].profileItem.key) {
                setAttributeChanged(true);
            } else {
                setAttributeChanged(false);
            }
        }
        setSelectedAttribute(value);
    };

    const updateAttributeLevel = (value: number) => {
        if (props.update) {
            if (value !== props.currentAttribute[0].profileItemTypeLevel.key) {
                setAttributeLevelChanged(true);
            } else {
                setAttributeLevelChanged(false);
            }
        }
        setSelectedAttributeLevel(value);
    };

    const updateLanguage = (value: number) => {
        if (props.update) {
            if (value !== props.currentAttribute[0].language.key) {
                setLanguageChanged(true);
            } else {
                setLanguageChanged(false);
            }
        }
        setLanguage(value);
    };

    const addAttrbitute = () => {
        if (checkIfDuplicates()) {
            return;
        }

        const attributeData: UpdateProfileType = {
            key: props.update ? props.currentAttribute[0].key : null,
            pkey: +props.pkey,
            organization: null,
            specialization: props.attributeName === "Education" ? specialization : null,
            approvedBy: null,
            //@ts-ignore
            initialCertYear:
                props.attributeName === "Professional Certifications" ? initialCertYear : null,
            //@ts-ignore
            certExpiresYear:
                props.attributeName === "Professional Certifications" ? certExpiresYear : null,
            languageKey: props.attributeName === "Languages" ? language : null,
            profileItemKey: selectedAttribute ? selectedAttribute : null,
            profileItemTypeKey: props.attributeKey,
            profileItemTypeLevelKey: selectedAttributeLevel ? selectedAttributeLevel : null,
        };

        const updateAttribute: UpdateProfile = {
            pKey: +props.pkey,
            payload: [attributeData],
        };

        if (props.update) {
            updateProfileAttribute(updateAttribute)
                .unwrap()
                .then(() => {
                    editAttributeEvent();
                    props.resultHandler(true, false);
                    handleClose();
                })
                .catch(() => {
                    props.resultHandler(false, false);
                });
            return;
        }

        addProfileAttributes(updateAttribute)
            .unwrap()
            .then(() => {
                addAttributeEvent();
                props.resultHandler(true, true);
                handleClose();
            })
            .catch(() => {
                props.resultHandler(false, true);
            });
    };

    const checkIfDisabled = () => {
        if (checkIfDuplicates()) {
            return true;
        }
        if (props.update) {
            if (props.attributeName === "Languages") {
                return !language;
            }
            if (props.attributeName === "Professional Certifications") {
                return !initialCertYear && !selectedAttribute;
            }
            if (props.attributeName === "Education") {
                return !specialization && !selectedAttribute && !selectedAttributeLevel;
            }
            return !selectedAttribute && !selectedAttributeLevel;
        }
        if (props.attributeName === "Professional Certifications") {
            return !selectedAttribute || !initialCertYear;
        } else if (props.attributeName === "Languages") {
            return !language;
        } else {
            return !selectedAttribute || !selectedAttributeLevel;
        }
    };

    const checkIfDuplicates = () => {
        for (let i = 0; i < props.originalObject.length; i++) {
            if (props.attributeName === "Languages") {
                if (props.originalObject[i].language?.key === language && languageChanged) {
                    return true;
                }
            } else if (
                props.originalObject[i].profileItem?.key === selectedAttribute &&
                attributeChanged &&
                props.attributeName !== "Education" &&
                props.attributeName !== "Industry Experience"
            ) {
                return true;
            } else if (
                props.attributeName === "Education" ||
                props.attributeName === "Industry Experience"
            ) {
                if (
                    props.originalObject[i].profileItem?.key === selectedAttribute &&
                    (attributeChanged || attributeLevelChanged) &&
                    props.originalObject[i].profileItemTypeLevel?.key === selectedAttributeLevel
                ) {
                    return true;
                }
            }
        }
    };

    return (
        <CclDrawer
            open={props.open}
            onClose={handleClose}
            title={`${props.update ? "Edit" : "Add"} Attribute`}
        >
            <Stack direction={"column"} spacing={3} sx={{ mb: 3, mt: 4 }}>
                {props.attributeName !== "Languages" && availableAttributes.length > 0 && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Attribute</InputLabel>
                                <Select
                                    label="Attribute"
                                    value={selectedAttribute}
                                    onChange={(e) => updateAttribute(e.target.value as number)}
                                    defaultValue={
                                        props.update
                                            ? props.currentAttribute[0].profileItem.key
                                            : null
                                    }
                                >
                                    {availableAttributes.map((item: any) => (
                                        <MenuItem value={item.key}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {availableAttributeLevels.length > 0 && (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Level</InputLabel>
                                    <Select
                                        label="Level"
                                        value={selectedAttributeLevel}
                                        onChange={(e) =>
                                            updateAttributeLevel(e.target.value as number)
                                        }
                                        sx={{ width: "100%" }}
                                        defaultValue={
                                            props.update
                                                ? props.currentAttribute[0].profileItemTypeLevel.key
                                                : null
                                        }
                                    >
                                        {availableAttributeLevels.map((item: any) => (
                                            <MenuItem value={item.key}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {props.attributeName === "Professional Certifications" && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Initial Certification Year"
                                        placeholder="yyyy"
                                        variant="outlined"
                                        value={initialCertYear}
                                        onChange={(e: any) => setInitialCertYear(e.target.value)}
                                        sx={{ width: "100%" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Certification Expires Year"
                                        variant="outlined"
                                        placeholder="yyyy"
                                        value={certExpiresYear}
                                        onChange={(e: any) => setCertExpiresYear(e.target.value)}
                                        sx={{ width: "100%" }}
                                    />
                                </Grid>
                            </>
                        )}

                        {props.attributeName === "Education" && (
                            <Grid item xs={12}>
                                <TextField
                                    label="Specialization"
                                    variant="outlined"
                                    value={specialization}
                                    onChange={(e) => setSpecialization(e.target.value)}
                                    sx={{ width: "100%" }}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                {props.attributeName === "Languages" && availableAttributes.length > 0 && (
                    <FormControl fullWidth>
                        <InputLabel>Language</InputLabel>
                        <Select
                            value={language}
                            label="Language"
                            onChange={(e: any) => updateLanguage(e.target.value as number)}
                        >
                            {availableAttributes.map((item: any) => (
                                <MenuItem value={item.key}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mt: 4 }}>
                <CclUnrestrictedLoadingButton
                    loading={addLoading || updateLoading}
                    disabled={checkIfDisabled()}
                    onClick={addAttrbitute}
                >
                    Save
                </CclUnrestrictedLoadingButton>
                <CclUnrestrictedButton onClick={handleClose} sx={{ float: "right" }}>
                    Cancel
                </CclUnrestrictedButton>
            </Box>
        </CclDrawer>
    );
};

export default AddAttributeDrawer;
