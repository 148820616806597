import * as React from "react";
import {
    Alert,
    AlertColor,
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import { CclInfoTableActionFieldProps } from "./cclInfoTableActionField";
import CclInfoTableValueField, {
    CclInfoTableValueFieldProps,
    eInfoTableValueFieldEditType,
} from "./cclInfoTableValueField";
import CclUnrestrictedButton from "../cclButtons/cclUnrestrictedButton";

export type CclInfoTableRowTemplate = {
    id: string;
    fieldType: string;
    editable: boolean;
    label: string;
    required: boolean;
    value: string;
    actionField: CclInfoTableActionFieldProps | null;
    valueField: CclInfoTableValueFieldProps;
};

export interface CclInfoTableProps {
    template: CclInfoTableRowTemplate[];
    data: object;
    onSave?: any;
    onChange?: (changes: CclInfoTableFormValue[]) => void;
    readonly?: boolean;
    disableSave?: boolean;
    changeAlertSeverity?: AlertColor;
    changeAlertMessage?: string;
    skipConfirmation?: boolean;
}

export type CclInfoTableFormValue = {
    name: string;
    value: any;
    required: boolean;
};

function getObjectField(obj: object, keyToFind: string, fieldType: string, editable: boolean): any {
    for (const [k, v] of Object.entries(obj)) {
        if (k === keyToFind) {
            if (v === null) {
                return "";
            }
            // if dates/times are editable we need to specify en-US as the locale as the info edit components expect that format for the date
            if (fieldType === "Date") {
                var d = new Date(v);
                return editable ? d.toLocaleDateString("en-US") : d.toLocaleDateString();
            } else if (fieldType === "DateTime") {
                var dt = new Date(v);
                return editable ? dt.toLocaleString("en-US") : dt.toLocaleString();
            }
            return v as string;
        }
    }
    return null;
}

const CclInfoTable: React.FC<CclInfoTableProps> = (props) => {
    const [rows, setRows] = React.useState<CclInfoTableRowTemplate[]>();
    const [changedValues, setChangedValues] = React.useState<CclInfoTableFormValue[]>([]);
    const [showChanges, setshowChanges] = React.useState<boolean>(false);
    const [missingRequired, setMissingRequired] = React.useState<boolean>(false);
    const claimsService = React.useMemo(() => new UserClaimsService(), []);
    const isReadOnly =
        props.readonly || (props.readonly === undefined && claimsService.IsReadOnly());

    const onChange = (name: string, value: any, required: boolean) => {
        let formVal = changedValues.find((v) => v.name === name);
        let newFormValues: CclInfoTableFormValue[] = [];

        if (formVal === undefined || formVal === null) {
            let newval: CclInfoTableFormValue = { name: name, value: value, required: required };
            newFormValues = [...changedValues, newval];
        } else {
            newFormValues = changedValues.map((v) =>
                v.name === name ? { name: name, value: value, required: required } : v
            );
        }
        setshowChanges(true);
        setChangedValues(newFormValues);
        if (props.onChange) props.onChange(newFormValues);
    };

    const handleSave = () => {
        let requiredArr = [];
        let lock = [];
        //loop through rows to identify required rows
        for (let i = 0; i < (rows ? rows.length : 0); i++) {
            if (!rows) {
                break;
            }
            if (rows[i].required && !rows[i].value) {
                requiredArr.push(rows[i]);
                for (let j = 0; j < changedValues.length; j++) {
                    if (rows[i].id === changedValues[j].name) {
                        lock.push("passed");
                    }
                }
            }
        }

        for (let i = 0; i < changedValues.length; i++) {
            if (changedValues[i].required && !changedValues[i].value) {
                setMissingRequired(true);
                return;
            }
        }
        //if the lock is different from the requirments, set missing and lock from saving
        if (lock.length !== requiredArr.length) {
            setMissingRequired(true);
            return;
        }
        setMissingRequired(false);
        setshowChanges(false);
        if (props.onSave) {
            props.onSave(changedValues);
        }
        setChangedValues([]);
    };

    React.useEffect(() => {
        var r = [...props.template];
        r.forEach((row) => {
            if (isReadOnly) {
                row.editable = false;
                row.required = false;
            }
            let val = getObjectField(props.data, row.id, row.fieldType, row.editable);
            if (val != null) {
                row.value = val;
                row.valueField.value = val;
                if (row.valueField.editFieldType === eInfoTableValueFieldEditType.Checkbox) {
                    row.valueField.checked = val;
                }
            }
            row.valueField.id = row.id;
        });
        setRows(r);
    }, [props.template, props.data, isReadOnly, claimsService]);

    if (rows == null) {
        return <span>No data</span>;
    }

    return (
        <form>
            <Table aria-label="info table" size="small">
                <TableBody>
                    {rows &&
                        rows
                            .filter(
                                (row) =>
                                    row.valueField.editFieldType !==
                                    eInfoTableValueFieldEditType.NotesInput
                            )
                            .map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            fontSize: 14,
                                            borderBottom: "transparent",
                                            width: "fi",
                                        }}
                                    >
                                        {row.label}
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        sx={{
                                            fontSize: 14,
                                            borderBottom: "transparent",
                                            padding: "3px 12px",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                height: "2.25rem",
                                                alignContent: "center",
                                                p: "5px",
                                                backgroundColor: "#F0F0F0",
                                                display: "flex",
                                            }}
                                        >
                                            <CclInfoTableValueField
                                                {...row.valueField}
                                                url={
                                                    row.valueField.url
                                                        ? row.valueField.url.replace(
                                                              "@@@",
                                                              row.value
                                                          )
                                                        : null
                                                }
                                                editable={row.editable}
                                                onChange={onChange}
                                                required={row.required}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                </TableBody>
            </Table>
            {rows.filter(
                (row) => row.valueField.editFieldType === eInfoTableValueFieldEditType.NotesInput
            ).length > 0
                ? rows
                      .filter(
                          (row) =>
                              row.valueField.editFieldType ===
                              eInfoTableValueFieldEditType.NotesInput
                      )
                      .map((row) => (
                          <CclInfoTableValueField
                              {...row.valueField}
                              key={row.id}
                              label={row.label}
                              editable={row.editable}
                              onChange={onChange}
                              required={row.required}
                              readonly={isReadOnly}
                          />
                      ))
                : null}
            {rows.filter((r) => r.editable === true).length > 0 && (
                <Box sx={{ m: 2 }}>
                    <Stack direction="row" justifyContent="end" spacing={2} margin={3}>
                        {showChanges && !missingRequired && (
                            <Alert
                                severity={props.changeAlertSeverity ?? "info"}
                                sx={{ width: "100%" }}
                            >
                                {props.changeAlertMessage ??
                                    "You have pending changes. Press Save to commit them."}
                            </Alert>
                        )}
                        {missingRequired && (
                            <Alert severity="error" sx={{ width: "100%" }}>
                                All required fields must have values
                            </Alert>
                        )}
                        {!isReadOnly && (
                            <CclUnrestrictedButton
                                sx={{ float: "right" }}
                                size="small"
                                onClick={handleSave}
                                disabled={changedValues.length === 0 || props.disableSave}
                            >
                                Save
                            </CclUnrestrictedButton>
                        )}
                    </Stack>
                </Box>
            )}
        </form>
    );
};

export default CclInfoTable;
