import * as React from "react";
import { DataGridPro, GridColumns, GridSelectionModel } from "@mui/x-data-grid-pro";
import { Asset, AssetVersionStatus } from "../../services/types/enterpriseParticipantApiTypes";
import { PersonInfo } from "../../models/personInfo";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridToolbar from "../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import AssessmentRescoredCell from "../sessions/sessionDetail/files/participantFileDataGrid/assessmentRescoredCell";

type PaxAssetRow = {
    id: number;
    imkey: number;
    firstName: string;
    lastName: string;
    fileName: string;
    type: string;
    isReleased: boolean;
    releaseDate: Date | null;
    createdDate: Date;
    latestVersionId: string;
    latestScoredDate: Date | null;
    versionStatuses: AssetVersionStatus[];
};

export interface ScoringReportDownloadsDataGridProps {
    assets: Asset[];
    participants: PersonInfo[];
    handleEvent: (eventName: string, selectedIds: (number | string)[]) => void;
}

export const ScoringReportDownloadsDataGrid: React.FC<ScoringReportDownloadsDataGridProps> = (
    props
) => {
    const [paxAssets, setPaxAssets] = React.useState<PaxAssetRow[]>([]);
    const [ids, setIds] = React.useState<(number | string)[]>([]);

    function getFullName(params: any) {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
    }

    const COLUMNS = [
        {
            field: "name",
            headerName: "Participant",
            valueGetter: getFullName,
            type: "string",
            flex: 1,
        },
        {
            field: "fileName",
            headerName: "File Name",
            type: "string",
            flex: 1,
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            description: "The date and time the original file was stored.",
            valueGetter: (params: any) => new Date(params.row.createdDate),
            type: "date",
            renderCell: (params: any) => {
                return <span>{params?.value.toLocaleString()}</span>;
            },
            flex: 0.65,
        },
        {
            field: "releaseDate",
            headerName: "Released Date",
            description:
                "The date and time the LATEST version of the file was released (empty if not released).",
            valueGetter: (params: any) => new Date(params.row.releaseDate),
            type: "date",
            renderCell: (params: any) => {
                if (params.row.isReleased && params.row.releaseDate != null) {
                    return <span>{params?.value.toLocaleString()}</span>;
                }
                return <span></span>;
            },
            flex: 0.65,
        },
        {
            field: "latestScoredDate",
            headerName: "Latest Scored Date",
            description:
                "The date and time the LATEST version of the assessment was scored (empty if not a CCL scored assessment).",
            valueGetter: (params: any) => new Date(params.row.latestScoredDate),
            type: "date",
            renderCell: (params: any) => {
                if (params.row.latestScoredDate != null) {
                    return (
                        <React.Fragment>
                            <span>{params?.value.toLocaleString()}</span>
                            {params.row.versionStatuses.length > 1 && (
                                <Tooltip
                                    title={`${params.row.versionStatuses.length} versions`}
                                    enterDelay={150}
                                    leaveDelay={150}
                                >
                                    <InfoIcon color="primary" sx={{ ml: 2 }} />
                                </Tooltip>
                            )}
                        </React.Fragment>
                    );
                }
                return <span></span>;
            },
            flex: 0.65,
        },
        {
            field: "history",
            headerName: "History",
            description: "View dates for all versions of the file.",
            renderCell: (params: any) => {
                return (
                    <AssessmentRescoredCell
                        latestVersionId={params.row.latestVersionId}
                        versionStatuses={params.row.versionStatuses}
                        isAssessment={params.row.type === "Assessment"}
                    />
                );
            },
            flex: 0.3,
        },
    ];

    React.useEffect(() => {
        if (
            props.assets &&
            props.assets.length > 0 &&
            props.participants &&
            props.participants.length > 0
        ) {
            const tempAssets = props.assets.filter(
                (a) => props.participants.find((p) => p.imKey === a.imKey) != null
            );
            const pa: PaxAssetRow[] = tempAssets.map((a) => {
                const pax = props.participants.find((p) => p.imKey === a.imKey); // pax is populate because filter above
                const par: PaxAssetRow = {
                    id: a.id,
                    imkey: a.imKey,
                    firstName: pax?.firstName ?? "Unknown",
                    lastName: pax?.lastName ?? "Unknown",
                    fileName: a.name,
                    type: a.fileType,
                    isReleased: a.isReleased,
                    releaseDate: a.releaseDate,
                    createdDate: a.createdDate,
                    versionStatuses: a.versionStatuses,
                    latestVersionId: a.latestVersionId,
                    latestScoredDate: a.latestScoredDate,
                };
                return par;
            });
            setPaxAssets(
                pa.sort((a, b) => {
                    return (
                        a.lastName.localeCompare(b.lastName) ||
                        a.firstName.localeCompare(b.firstName) ||
                        a.fileName.localeCompare(b.fileName)
                    );
                })
            );
        } else {
            setPaxAssets([]);
        }
    }, [props.assets, props.participants]);

    return props.assets.length === 0 ? null : (
        <DataGridPro
            getRowId={(row) => row.id}
            rows={paxAssets == null ? [] : paxAssets}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={{
                toolbar: {
                    showSaveState: true,
                    hideSettingsButtons: true,
                    miscButtons: [
                        {
                            id: "download",
                            label: "Download Selected",
                            caption: "Download",
                            disable: ids?.length <= 0,
                            color: "warning",
                            onClick: () => {
                                props.handleEvent("download", ids);
                            },
                        },
                    ],
                    btnClick: (buttonName: string) => props.handleEvent(buttonName, ids),
                },
            }}
            disableSelectionOnClick
            checkboxSelection={true}
            onSelectionModelChange={(ids: GridSelectionModel) => {
                setIds(ids.map((i) => i));
            }}
            sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                },
            }}
        />
    );
};

export default ScoringReportDownloadsDataGrid;
