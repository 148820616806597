import * as React from "react";
import {
    useLazyGetCclParticipantsWithFilesByCoachImKeyQuery,
    useLazyGetParticipantCompassUrlQuery,
} from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import FlexGrid from "../../../../../layouts/flexGrid";
import { ResourceRegistrationsListDataGridPro } from "./resourceParticipantList";
import { Alert, AlertColor, AlertTitle, Stack } from "@mui/material";
import ComponentLoader from "../../../../../components/common/componentLoader";
import { getErrorMsg } from "../../../../../utilities/rtkQueryErrorHelpers";
import {
    AssetDownloadRequest,
    IndividualAssetsList,
    IndividualAssetsReleaseRequest,
} from "../../../../../services/types/assetApiTypes";
import { useReleaseAssetsMutation } from "../../../../../services/cclTokenedAssetsApi";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import {
    Asset,
    GetCompassUrlResponse,
    Participant,
} from "../../../../../services/types/enterpriseParticipantApiTypes";
import { useSendCompassInvitationsMutation } from "../../../../../services/cclTokenedGrandCentralApi";
import {
    RecipientInfo,
    SendCompassInvitationRequest,
    SendInvitationResponse,
} from "../../../../../services/types/rtkQueryTypes";
import { FileDownloadService } from "../../../../../services/fileDownloadService/fileDownloadService";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclAlertSnackbar from "../../../../../components/common/cclAlertSnackbar";

interface ResourceRegistrationsPanelProps {
    imkey: number;
    alternateEventOnClickHandler?: any;
}

const ResourceRegistrationsPanel: React.FC<ResourceRegistrationsPanelProps> = (props) => {
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const [getParticipantsTrigger, { data: participants, isLoading, isError, error }] =
        useLazyGetCclParticipantsWithFilesByCoachImKeyQuery();
    const [releaseAssets] = useReleaseAssetsMutation();
    const { logEvent } = useLogAccessEvent();
    const [sendCompassInvitations] = useSendCompassInvitationsMutation();
    const [getCompassUrl] = useLazyGetParticipantCompassUrlQuery();

    const downloadService = new FileDownloadService();
    const claimsService = new UserClaimsService();
    const allowParticipantOperations: boolean =
        claimsService.IsUserCclAdminOrSuperAdmin() ||
        props.imkey.toString() === claimsService.GetCurrentUserId();
    const isResourceCurrentUser = props.imkey.toString() === claimsService.GetCurrentUserId();

    React.useEffect(() => {
        if (props.imkey != null) getParticipantsTrigger(props.imkey);
    }, [getParticipantsTrigger, props.imkey]);

    const getParticipantsWithSelectedAiKeys = (aikeys: number[]): Participant[] => {
        let pax: Participant[] = [];
        if (participants != null && participants.length > 0) {
            participants.forEach((p) => {
                const foundAssets = p.assets.filter((a) => aikeys.some((ak) => ak === a.id));
                if (foundAssets.length > 0) pax.push(p);
            });
        }
        return pax;
    };

    const getSelectedParticipantAssetsByEsiKeys = (
        esikeys: number[],
        aikeys: number[]
    ): Asset[] => {
        let assets: Asset[] = [];
        let pax: Participant[] =
            participants?.filter((p) => esikeys.some((ek) => p.esiKey === ek)) ?? [];
        if (pax != null && pax.length > 0) {
            pax.forEach((p) => {
                const foundAssets = p.assets.filter((a) => aikeys.some((ak) => ak === a.id));
                assets = assets.concat(foundAssets);
            });
        }
        return assets;
    };

    const getParticipantAssetsByAiKeys = (aikeys: number[]): Asset[] => {
        let assets: Asset[] = [];
        if (participants != null && participants.length > 0) {
            participants.forEach((p) => {
                const foundAssets = p.assets.filter((a) => aikeys.some((ak) => ak === a.id));
                assets = assets.concat(foundAssets);
            });
        }
        return assets;
    };

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleEvent = async (evt: string, ids: number[]) => {
        switch (evt) {
            case "download":
                if (ids === undefined || ids.length <= 0) {
                    ShowSnackbarElement(
                        "No participant files selected. Please select one or more participant files to download.",
                        "warning"
                    );
                    return;
                }

                setShowSpinner(true);
                let filename = "ParticipantFiles.zip";
                if (ids.length === 1) {
                    const paxAssets = getParticipantAssetsByAiKeys([ids[0]]);
                    filename = paxAssets[0].name;
                    if (paxAssets[0].fileExtension !== "") {
                        var fileExt = paxAssets[0].name.split(".").pop();
                        if (fileExt?.toLowerCase() !== paxAssets[0].fileExtension.toLowerCase())
                            filename += paxAssets[0].fileExtension;
                    }
                }

                const downloadrequest: AssetDownloadRequest = {
                    fname: filename,
                    aikeys: ids,
                    flatzip: false,
                };

                downloadService
                    .DownloadAssets(downloadrequest)
                    .then(() => {
                        logEvent("UserDownloadedParticipantFiles", null);
                        ShowSnackbarElement("Downloading complete.", "success");
                        setShowSpinner(false);
                    })
                    .catch((error) => {
                        const msg = error.message ?? "Unknown error";
                        ShowSnackbarElement(`Error downloading: ${msg}`, "error");
                        setShowSpinner(false);
                    });
                break;

            case "release":
                const selectedParticipants = getParticipantsWithSelectedAiKeys(ids);
                if (selectedParticipants.length === 0) return;

                const allowedEsiKeys: number[] =
                    selectedParticipants
                        .filter(
                            (p) =>
                                (p.coachCanReleasePaxFiles && isResourceCurrentUser) ||
                                claimsService.IsUserCclAdminOrSuperAdmin()
                        )
                        .map((p) => p.esiKey) ?? [];
                if (allowedEsiKeys.length <= 0) {
                    ShowSnackbarElement(
                        "All selected files are already released or can't be released.",
                        "warning"
                    );
                    return;
                }

                const paxAssets = getSelectedParticipantAssetsByEsiKeys(allowedEsiKeys, ids);
                // don't attempt to release consultant reports
                const releasablePaxAssets: Asset[] = paxAssets.filter(
                    (a) => a.fileType !== "Consultant Report" && !a.isReleased
                );

                if (releasablePaxAssets === undefined || releasablePaxAssets.length <= 0) {
                    ShowSnackbarElement(
                        "All selected files either can't be released or are already released.",
                        "warning"
                    );
                    return;
                }

                // construct the request object
                const individualsList: IndividualAssetsList[] = selectedParticipants
                    .map((p) => {
                        const thispaxassets: number[] = releasablePaxAssets
                            .filter((a) => a.imKey === p.imKey)
                            .map((pa) => pa.id);
                        return {
                            esikey: p.esiKey,
                            imkey: p.imKey,
                            aikeys: thispaxassets,
                        };
                    })
                    .filter((i) => i.aikeys.length > 0);

                if (individualsList.length <= 0) {
                    ShowSnackbarElement(
                        "All selected files either can't be released or are already released.",
                        "warning"
                    );
                    return;
                }

                const releaserequest: IndividualAssetsReleaseRequest = {
                    individualAssetLists: individualsList.filter((i) => i.aikeys.length > 0),
                };

                setShowSpinner(true);

                releaseAssets(releaserequest)
                    .unwrap()
                    .then(() => {
                        logEvent("UserReleasedParticipantFiles", null);

                        getParticipantsTrigger(props.imkey)
                            .unwrap()
                            .then(() => {
                                ShowSnackbarElement("Release complete.", "success");
                                setShowSpinner(false);
                            })
                            .catch((error) => {
                                ShowSnackbarElement(
                                    "Release complete, but failed to refresh participant data",
                                    "success"
                                );
                                setShowSpinner(false);
                            });
                    })
                    .catch((error) => {
                        const msg = error.data ?? "Unknown error";
                        ShowSnackbarElement(`Error releasing files: ${msg}`, "error");
                        setShowSpinner(false);
                    });
                break;

            case "activate":
                if (participants == null || participants.length <= 0) return;

                let allowedPax: Participant[] = participants
                    .filter((p) => ids.some((i) => i === p.esiKey))
                    .filter(
                        (p) =>
                            p.sessionHasCompassSku &&
                            p.sessionHostPlatform !== "Leadership Accelerator Platform"
                    )
                    .filter(
                        (p) =>
                            (p.coachCanActivateCompass && isResourceCurrentUser) ||
                            claimsService.IsUserCclAdminOrSuperAdmin()
                    );

                if (allowedPax.length <= 0) {
                    ShowSnackbarElement(
                        `Compass activation could NOT be completed for any of the selected participants. These particpants are either in a session that does not have Compass included, or does not allow assigned resources to activate Compass, or is hosted on Leadership Accelerator which requires Compass activation at the session level.`,
                        "warning"
                    );
                    return;
                }

                const recipientList: RecipientInfo[] = allowedPax.map((p) => {
                    return {
                        email: p.emailAddress,
                        firstName: p.firstName,
                        lastName: p.lastName,
                    };
                });

                const request: SendCompassInvitationRequest = {
                    recipients: recipientList,
                    disableEmail: false,
                    autoAccept: false,
                    ignorePendingInvites: false,
                };

                setShowSpinner(true);

                sendCompassInvitations(request)
                    .unwrap()
                    .then((response: SendInvitationResponse) => {
                        setShowSpinner(false);
                        if (response === undefined || response.numberInivitationsSent <= 0) {
                            ShowSnackbarElement(
                                "Compass could not be activated for any of the selected participants. These particpants may already have Compass accounts or invitations, or are in a session that does not have Compass included or does not allow assigned resources to activate Compass, or is hosted on Leadership Accelerator which requires Compass activation at the session level.",
                                "warning"
                            );
                            return;
                        }
                        logEvent("UserActivatedCompass", null);
                        if (response.numberFailed > 0) {
                            ShowSnackbarElement(
                                `Compass activation not completed for ${response.numberFailed} of ${recipientList.length} valid selected participants. These participants may already have a Compass account.`,
                                "warning"
                            );
                        } else {
                            if (recipientList.length < ids.length) {
                                ShowSnackbarElement(
                                    `Compass activation was completed for ${recipientList.length} of the selected participants. The others are either in a session that does not have Compass included, or does not allow assigned resources to activate Compass, or is hosted on Leadership Accelerator which requires Compass activation at the session level.`,
                                    "warning"
                                );
                            } else {
                                ShowSnackbarElement("Compass activation complete.", "success");
                            }
                        }
                    })
                    .catch((error) => {
                        setShowSpinner(false);
                        const msg = error.data ?? "Unknown error";
                        ShowSnackbarElement(`Error: ${msg}`, "error");
                    });
                break;
            default:
                return;
        }
    };

    const viewCompass = (imkey: number) => {
        // get the url
        getCompassUrl(imkey)
            .unwrap()
            .then((response: GetCompassUrlResponse) => {
                window.open(response.tempCompassUrl);
                logEvent("UserViewedCompass", null);
            })
            .catch((error) => {
                const msg = error.data ?? "Unknown error";
                ShowSnackbarElement(`Error opening Compass for participant: ${msg}`, "error");
                setShowSpinner(false);
            });
    };

    if (isError) {
        let msg = getErrorMsg(error);
        console.log(msg);
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (isLoading) {
        return <ComponentLoader msg="Loading Participants" />;
    }

    return (
        <Stack spacing={3}>
            <Alert severity={"warning"}>
                Please Note: You may automatically lose access to PDF files you have downloaded
                after 60 days. You can return to CCL Access to download another copy if that
                happens. We also kindly request you delete any downloaded files within 60 days of
                downloading them. This is to ensure CCL complies with data security and privacy
                legislation.
            </Alert>
            {showSpinner ? <ComponentLoader msg={""} /> : null}
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <FlexGrid>
                <ResourceRegistrationsListDataGridPro
                    isLoading={isLoading}
                    participants={participants}
                    handleEvent={handleEvent}
                    viewCompass={viewCompass}
                    allowOperations={allowParticipantOperations}
                    alternativeEventOnClickHandler={props.alternateEventOnClickHandler}
                />
            </FlexGrid>
        </Stack>
    );
};

export default ResourceRegistrationsPanel;
