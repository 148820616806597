import * as React from "react";
import {
    DataGridPro,
    GridColumns,
    GridRenderCellParams,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { ProfileContract } from "../../../../services/types/enterpriseCoachingApiTypes";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

const COLUMNS = [
    {
        field: "year",
        headerName: "Year",
        width: 150,
        type: "string",
        valueGetter: (params: GridRenderCellParams) => {
            return params.row.contract.year;
        },
    },
    {
        field: "status",
        headerName: "Status",
        width: 150,
        type: "string",
        valueGetter: (params: GridRenderCellParams) => {
            //TODO: Move this to api
            let s = params.row.status;
            if (s === 0) return "Pending";
            if (s === 1) return "Signed";
            if (s === 2) return "Declined";
            else return "";
        },
    },
    {
        field: "title",
        headerName: "Contract",
        type: "string",
        valueGetter: (params: GridRenderCellParams) => {
            return params.row.contract.title;
        },
        flex: 1,
    },
];

export type ContractsDataGridProps = {
    isLoading: boolean;
    data: ProfileContract[] | undefined;
    handleEvent: (evt: string, ids: number[]) => void;
    readonly?: boolean;
    eventInProgress?: string;
};

export const ContractsDataGrid: React.FC<ContractsDataGridProps> = (props) => {
    const [contractIds, setContractIds] = React.useState<(number | string)[]>([]);
    const contracts = props.data === undefined ? [] : props.data;
    const claimsService = new UserClaimsService();
    const isReadOnly = props?.readonly ?? claimsService.IsReadOnly();

    const handleEvent = (evt: string) => {
        const ids: number[] = contractIds.length > 0 ? contractIds.map((r) => +r) : [];
        props.handleEvent(evt, ids);
    };

    return (
        <React.Fragment>
            <DataGridPro
                loading={props.isLoading}
                getRowId={(row) => row.profileContractId}
                rows={contracts}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={
                    isReadOnly
                        ? undefined
                        : {
                              toolbar: {
                                  showSaveState: true,
                                  hideSettingsButtons: true,
                                  miscButtons: [
                                      {
                                          id: "download",
                                          label: "Download Contract(s)",
                                          caption: "Download Contract(s)",
                                          color: "warning",
                                          disable: contractIds?.length > 0 ? false : true,
                                          returnSelected: true,
                                          onClick: (selectedRows: any[]) => {
                                              handleEvent("download");
                                          },
                                          loadingButton: true,
                                          isLoading: props.eventInProgress === "download",
                                      },
                                  ],
                                  btnClick: () => handleEvent("download"),
                                  anySelected: contractIds?.length > 0,
                              },
                          }
                }
                disableSelectionOnClick
                checkboxSelection={true}
                onSelectionModelChange={(ids: GridSelectionModel) => {
                    setContractIds([...ids.map((i) => i)]);
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "year", sort: "desc" }],
                    },
                }}
                autoHeight
            />
        </React.Fragment>
    );
};

export default ContractsDataGrid;
