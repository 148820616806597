import React from "react";
import { AlertColor, Box, Grid, Typography } from "@mui/material";
import {
    useFinalizeImageChunkUploadMutation,
    useUploadAssetChunkMutation,
} from "../../../../services/cclTokenedAssetsApi";
import { useSaveProfilePictureMutation } from "../../../../services/cclTokenedEnterpriseCoachingApi";
import { AccessEventIndividualDetails } from "../../../../services/types/accessEventTypes";
import returnDefaultUserImage from "../../../../utilities/defaultUserPicture";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclUnrestrictedButton from "../../../../components/common/cclButtons/cclUnrestrictedButton";
import EditImageDrawer from "./editImageDrawer";
import ComponentLoader from "../../../../components/common/componentLoader";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface ProfileImageProps {
    name: string;
    picture: string;
    refetch: () => void;
    imKey: string;
    restrictAccess?: boolean;
    fromResources?: boolean;
    email: string;
}

const ProfileImage: React.FC<ProfileImageProps> = (props) => {
    const chunksize = 1024 * 512; // .5 MB chunks
    let numChunks: number = 0;

    const [saveProfilePicture, { isSuccess }] = useSaveProfilePictureMutation();
    const [
        uploadChunk,
        { data: chunkUploadResponse, isSuccess: chunkSuccess, isError: chunkIsError },
    ] = useUploadAssetChunkMutation();
    const [
        finalizeUpload,
        { data: finalizeResponse, isSuccess: finalizeSuccess, isError: finalizeIsError },
    ] = useFinalizeImageChunkUploadMutation();

    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [currentChunk, setCurrentChunk] = React.useState<number>(1);
    const [acceptedFile, setAcceptedFile] = React.useState<File | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [edit, setEdit] = React.useState<boolean>(false);

    document.onkeydown = function (evt) {
        evt = evt || window.event;
        if (evt.keyCode === 27) {
            setEdit(false);
        }
    };

    const { logEvent } = useLogAccessEvent();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const editedProfilePictureEvent = (imkey: string) => {
        const tag: string = props.fromResources
            ? "ResourceProfilePictureUpdated"
            : "UserUpdatedProfilePicture";
        const evtData: AccessEventIndividualDetails = {
            imKey: imkey,
            email: props.email,
        };

        logEvent(tag, evtData);
    };

    // eslint-disable-next-line
    const callFinalizeUpload = (id: string) => {
        if (acceptedFile == null) return;
        let file: File = acceptedFile;
        let type = file.type;
        let typeArr = type.split("/");
        type = typeArr[1];
        let fd: FormData = new FormData();
        fd.append("id", id);
        fd.append("fileName", `${props.imKey}.jpg`);
        fd.append("contentType", file.type);
        fd.append("chunkCount", numChunks.toString());
        finalizeUpload(fd)
            .unwrap()
            .then(() => {
                editedProfilePictureEvent(props.imKey);
                setEdit(false);
                setAcceptedFile(null);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                ShowSnackbarElement("An error occurred while processing your request", "error");
            });
    };

    React.useEffect(() => {
        if (chunkSuccess) {
            if (chunkUploadResponse && chunkUploadResponse.success) {
                if (currentChunk >= numChunks) {
                    callFinalizeUpload(chunkUploadResponse.id);
                } else {
                    const newchunknum = currentChunk + 1;
                    const chunk: Blob | null = getChunk(newchunknum, acceptedFile);
                    if (chunk == null) return;
                    setCurrentChunk(newchunknum);
                    let fd: FormData = new FormData();
                    fd.append("id", chunkUploadResponse?.id ?? "");
                    fd.append("chunkNumber", newchunknum.toString());
                    fd.append("chunk", chunk);
                    uploadChunk(fd);
                }
            } else {
            }
        } else if (chunkIsError) {
            // add cancel upload here
            setEdit(false);
            setLoading(false);
            ShowSnackbarElement("An error has occured uploading your image", "error");
        }
        // eslint-disable-next-line
    }, [chunkIsError, chunkSuccess]);

    React.useEffect(() => {
        if (finalizeResponse) {
            setCurrentChunk(1);
            if (finalizeSuccess) {
                ShowSnackbarElement("Image uploaded succesfully.", "success");
                props.refetch();
                setLoading(false);
            } else {
                setLoading(false);
                ShowSnackbarElement("An error has occured uploading your image", "error");
            }
            setEdit(false);
        }
        // eslint-disable-next-line
    }, [finalizeSuccess, finalizeIsError]);

    if (acceptedFile) {
        numChunks = acceptedFile != null ? Math.ceil(acceptedFile.size / chunksize) : 0;
    }

    const getChunk = (chunknum: number, file: File | null): Blob | null => {
        if (file == null) return null;
        const start = (chunknum - 1) * chunksize;
        let end = chunknum * chunksize;
        if (end > file.size) end = file.size;
        return file.slice(start, end);
    };

    const changeProfilePicture = () => {
        setEdit(!edit);
    };

    const saveImage = (imageFile: File) => {
        let formData: FormData = new FormData();
        let fileExtension = "image/" + imageFile.name.split(".").pop();
        setEdit(false);
        if (numChunks === 1) {
            formData.append("picFile", imageFile.slice(0, imageFile.size) as Blob);
            formData.append("contentType", imageFile.type ? imageFile.type : fileExtension);
            setLoading(true);
            setAcceptedFile(null);
            uploadImage(formData);
        } else {
            //Upload chunks
            var chunk: Blob | null = getChunk(1, imageFile);
            if (chunk == null) return;
            formData.append("id", "none");
            formData.append("chunk", chunk);
            formData.append("contentType", imageFile.type);
            formData.append("chunkNumber", currentChunk.toString());
            setLoading(true);
            setAcceptedFile(imageFile);
            uploadChunk(formData);
        }
    };

    const uploadImage = (data: FormData) => {
        let obj = {
            imKey: props.imKey,
            payload: {
                picFile: data,
            },
        };
        saveProfilePicture(obj)
            .unwrap()
            .then(() => {
                editedProfilePictureEvent(props.imKey);
                ShowSnackbarElement("Image uploaded succesfully.", "success");
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                ShowSnackbarElement("An error occurred while processing your request", "error");
            });
        setEdit(false);
        setAcceptedFile(null);
    };

    React.useEffect(() => {
        if (!isSuccess) {
            return;
        }
        props.refetch();
        // eslint-disable-next-line
    }, [isSuccess, props.refetch]);

    const claimservice = new UserClaimsService();

    const checkAccess = (): boolean => {
        return !props.restrictAccess || claimservice.IsUserCclAdminOrSuperAdmin();
    };

    return (
        <Grid container spacing={2} sx={{ width: "100%" }} alignItems={"center"}>
            {loading ? <ComponentLoader msg="" /> : null}
            <Grid item xs={4}>
                <Typography variant="h6" sx={{ fontWeight: "bolder", textAlign: "left", ml: 5 }}>
                    {props.name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                {props.picture ? (
                    <Box
                        component="img"
                        sx={{
                            height: "100px",
                            width: "100px",
                            margin: "auto",
                            borderRadius: "50%",
                        }}
                        src={props.picture}
                    />
                ) : (
                    returnDefaultUserImage(props.name)
                )}
            </Grid>
            <Grid item xs={3}>
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                ) : null}
                {edit ? (
                    <EditImageDrawer
                        open={true}
                        onCancel={() => setEdit(false)}
                        saveImage={saveImage}
                    />
                ) : null}
            </Grid>
            <Grid item xs={3}>
                {checkAccess() ? (
                    <CclUnrestrictedButton
                        sx={{ float: "right", mr: 5 }}
                        onClick={changeProfilePicture}
                    >
                        <Typography variant="body2">Change Profile Picture</Typography>
                    </CclUnrestrictedButton>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default ProfileImage;
