import * as React from "react";
import { Stack, Typography } from "@mui/material";
import ContentPasteSearchTwoToneIcon from "@mui/icons-material/ContentPasteSearchTwoTone";

export interface CclSearchDefaultProps {
    line1: string;
    line2: string;
}

export const CclSearchDefault: React.FC<CclSearchDefaultProps> = (props) => {
    return (
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ mt: 15 }}>
            <Typography variant="h5">{props.line1}</Typography>
            <Typography variant="h6">{props.line2}</Typography>
            <ContentPasteSearchTwoToneIcon color="primary" sx={{ fontSize: "200px" }} />
        </Stack>
    );
};

export default CclSearchDefault;
