import * as React from "react";
import { Alert, AlertTitle, Card, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMsg } from "../../utilities/rtkQueryErrorHelpers";
import { updateNotificationSearchParams } from "../../app/slices/notificationListConfigSlice";
import CclErrorDialog from "../../components/common/cclErrorDialog";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import { useGetCclNotificationsMutation } from "../../services/cclTokenedNotificationApi";
import { NotificationsDataGridPro } from "./notificationDataGrid";
import CclDateWithTextSearchBar from "./cclDateWithTextSearchBar";
import ComponentLoader from "../../components/common/componentLoader";
import CclSearchDefault from "../../components/common/cclSearchDefault";

export const Notifications: React.FC = () => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.notificationListConfig);

    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>();
    const [searchString, setSearchString] = useState<string>(config.Keyword);
    const [searchResultString, setSearchResultString] = useState<string>("");
    const [showSearchToBroad, setShowSearchToBroad] = useState<boolean>(false);

    const [runSearch, { data, isLoading, isSuccess, isError, error }] =
        useGetCclNotificationsMutation();

   /* useEffect(() => {
        runSearch({
            keyword: searchString,
            start: config.StartDate,
            end: config.EndDate,
        });
        // eslint-disable-next-line
    }, []);*/

    React.useLayoutEffect(() => {
        if (isSuccess && data && data.length >= 30000) setShowSearchToBroad(true);

        let msg =
            searchString == null || searchString === "" || searchString === "*"
                ? `${
                      data?.length
                  } results for search (from ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()})`
                : `${
                      data?.length
                  } results for search (recipient email='${searchString.trim()}', from ${startDate?.toLocaleDateString()} to ${endDate?.toLocaleDateString()})`;
        setSearchResultString(msg);
        // eslint-disable-next-line
    }, [data]);

    const getNotifications = async (
        startDt: Date | null,
        endDt: Date | null,
        searchTerm: string
    ) => {
        if (startDt == null || endDt == null) {
            return;
        }

        let keyword = searchTerm == null || searchTerm === "" ? "*" : searchTerm.trim();
        let start = new Date(startDt.setHours(0o0, 0o0, 0o0, 0o0)).toISOString();
        let end = new Date(endDt.setHours(23, 59, 59, 999)).toISOString();
        setStartDate(startDt);
        setEndDate(endDt);
        setSearchString(searchTerm);

        runSearch({
            keyword: keyword,
            start: start,
            end: end,
        });

        dispatch(
            updateNotificationSearchParams({
                StartDate: start,
                EndDate: end,
                Keyword: keyword,
            })
        );
    };

    function showErrorAlert(msg: string | undefined) {
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (isError) {
        return showErrorAlert(getErrorMsg(error));
    }

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Emails
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Stack height={1} width={1}>
                <CclErrorDialog
                    open={showSearchToBroad}
                    title={"Too Many Results"}
                    msg={
                        "There are too many results for this search. Consider adjusting your search parameters and searching again."
                    }
                    onOk={() => setShowSearchToBroad(false)}
                />
                <Card
                    sx={{
                        width: 1,
                        height: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <CclDateWithTextSearchBar
                        executeSearch={getNotifications}
                        initialSearchTerm={searchString}
                        initialStartDate={null}
                        initialEndDate={null}
                        startDateLabel={"From Date"}
                        endDateLabel={"To Date"}
                        searchTermLabel={"Enter Email (optional)"}
                    />
                    {isLoading ?
                    (
                        // search, or loading in progress
                        <ComponentLoader msg={"Searching for emails..."} />) :  
                    isSuccess || data? (                  
                    <Grid
                        sx={{
                            height: 1,
                            width: 1,
                            padding: 2,
                            paddingTop: 0,
                        }}
                    >
                        <NotificationsDataGridPro
                            notifications={data}
                            isLoading={isLoading}
                            showResultBar={!isLoading}
                            resultBarText={searchResultString}
                        />
                    </Grid>) : (
                        <CclSearchDefault
                            line1="Start searching for your email"
                            line2="Enter Email or Date" />
                    )
}
                </Card>
            </Stack>
        </StandardLayout>
    );
};

export default Notifications;
