import * as React from "react";
import {
    useGetProductSaleParticipantsByFacilitatorEmailQuery,
    useLazyGetParticipantCompassUrlQuery,
    useReleaseProductSalesMutation,
} from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import FlexGrid from "../../../../../layouts/flexGrid";
import { Alert, AlertColor, AlertTitle, Stack } from "@mui/material";
import ResourceProductSalesListDataGridPro from "./resourceProductSalesList";
import { getErrorMsg } from "../../../../../utilities/rtkQueryErrorHelpers";
import { useSendCompassInvitationsMutation } from "../../../../../services/cclTokenedGrandCentralApi";
import {
    SendCompassInvitationRequest,
    SendInvitationResponse,
} from "../../../../../services/types/rtkQueryTypes";
import {
    GetCompassUrlResponse,
    ProductSaleParticipant,
} from "../../../../../services/types/enterpriseParticipantApiTypes";
import { FileDownloadService } from "../../../../../services/fileDownloadService/fileDownloadService";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclAlertSnackbar from "../../../../../components/common/cclAlertSnackbar";
import ComponentLoader from "../../../../../components/common/componentLoader";

interface ResourceProductSalesPanelProps {
    email: string;
}

const ResourceProductSalesPanel: React.FC<ResourceProductSalesPanelProps> = (props) => {
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [releaseReports /*, { data: releaseResults, isSuccess: releaseSuccess }*/] =
        useReleaseProductSalesMutation();
    const {
        data: productSales,
        isLoading,
        isError,
        error,
    } = useGetProductSaleParticipantsByFacilitatorEmailQuery(props.email, {
        skip: !props.email,
    });
    const { logEvent } = useLogAccessEvent();
    const [sendCompassInvitations] = useSendCompassInvitationsMutation();
    const [getCompassUrl] = useLazyGetParticipantCompassUrlQuery();
    const downloadService = new FileDownloadService();
    const claimsService = new UserClaimsService();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleEvents = (evt: string, ids: string[]) => {
        switch (evt) {
            case "download":
                if (ids === undefined || ids.length <= 0) {
                    ShowSnackbarElement("You must select at least one item to download.", "error");
                    return;
                }

                let filename = "ProductSales.zip";
                if (ids.length === 1) {
                    const ps = productSales?.find((p) => p.reportId === ids[0]);
                    if (ps == null) return; // shouldn't happen but just in case
                    filename = `${ps.fullName} ${ps.assessmentType}.pdf`;
                }

                setShowSpinner(true);

                downloadService
                    .DownloadProductSaleReports({ fname: filename, reportids: ids })
                    .then(() => {
                        logEvent("UserDownloadedProductSale", null);
                        setShowSpinner(false);
                        ShowSnackbarElement("Assesment(s) downloaded.", "success");
                    })
                    .catch((error) => {
                        setShowSpinner(false);
                        const msg = error.message ?? "Unknown error";
                        ShowSnackbarElement(`Error: ${msg}`, "error");
                    });
                break;
            case "activateCompass":
                if (ids === undefined || ids.length <= 0) {
                    ShowSnackbarElement("You must select at least one item to activate.", "error");
                    return;
                }

                setShowSpinner(true);
                releaseReports(ids)
                    .unwrap()
                    .then((releaseResults) => {
                        if (releaseResults === undefined || releaseResults.length <= 0) {
                            setShowSpinner(false);
                            ShowSnackbarElement("No reports were released to Compass.", "warning");
                            return;
                        }

                        const releasedReportIds: string[] =
                            releaseResults?.filter((r) => r.success).map((r) => r.reportId) ?? [];

                        if (releasedReportIds != null && releasedReportIds.length > 0) {
                            const prodSaleParticipants: ProductSaleParticipant[] =
                                productSales?.filter((ps) =>
                                    releasedReportIds.some((r) => r === ps.reportId)
                                ) ?? [];
                            const sendInvitesPayload: SendCompassInvitationRequest = {
                                recipients: prodSaleParticipants.map((p) => {
                                    return {
                                        email: p.emailAddress,
                                        firstName: p.firstName,
                                        lastName: p.lastName,
                                    };
                                }),
                                disableEmail: false,
                                autoAccept: false,
                                ignorePendingInvites: false,
                            };
                            sendCompassInvitations(sendInvitesPayload)
                                .unwrap()
                                .then((response: SendInvitationResponse) => {
                                    setShowSpinner(false);
                                    let msg: string =
                                        response.numberInivitationsSent !== 0
                                            ? `Activation successful for ${response.numberInivitationsSent} report(s). `
                                            : "";

                                    if (response.numberFailed > 0)
                                        msg = `${msg}Failed to send Compass invitations for ${response.numberFailed} report(s).`;

                                    const severity: AlertColor =
                                        response.numberInivitationsSent === 0
                                            ? "error"
                                            : response.numberFailed === 0
                                            ? "success"
                                            : "warning";

                                    ShowSnackbarElement(msg, severity);
                                    if (response.numberInivitationsSent > 0) {
                                        logEvent("UserActivatedProductSales", null);
                                    }
                                })
                                .catch((error) => {
                                    setShowSpinner(false);
                                    const msg = error.data ?? "Unknown error";
                                    ShowSnackbarElement(`Error: ${msg}`, "error");
                                });
                        } else {
                            setShowSpinner(false);
                            ShowSnackbarElement(
                                "Error: Failed to release any of the selected reports.",
                                "error"
                            );
                        }
                    })
                    .catch((error) => {
                        setShowSpinner(false);
                        const msg = error.data ?? "Unknown error";
                        ShowSnackbarElement(`Error: ${msg}`, "error");
                    });
                break;
            default:
                break;
        }
    };

    const viewCompass = (imkey: number) => {
        // get the url
        getCompassUrl(imkey)
            .unwrap()
            .then((response: GetCompassUrlResponse) => {
                window.open(response.tempCompassUrl);
                logEvent("UserViewedCompass", null);
            })
            .catch((error) => {
                const msg = error.data ?? "Unknown error";
                ShowSnackbarElement(`Error: ${msg}`, "error");
            });
    };

    if (isError) {
        let msg = getErrorMsg(error);
        console.log(msg);
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    return (
        <Stack spacing={3}>
            <Alert severity={"warning"}>
                Please Note: You may automatically lose access to PDF files you have downloaded
                after 60 days. You can return to CCL Access to download another copy if that
                happens. We also kindly request you delete any downloaded files within 60 days of
                downloading them. This is to ensure CCL complies with data security and privacy
                legislation.
            </Alert>
            {showSpinner ? <ComponentLoader msg={""} /> : null}
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <FlexGrid>
                <ResourceProductSalesListDataGridPro
                    isLoading={isLoading}
                    productSales={productSales ?? []}
                    handleEvent={handleEvents}
                    viewCompass={viewCompass}
                    isUsersData={
                        props.email.toLowerCase() ===
                        claimsService.GetCurrentUserEmail().toLowerCase()
                    }
                />
            </FlexGrid>
        </Stack>
    );
};

export default ResourceProductSalesPanel;
