import * as React from "react";
import ScoringCalendar from "./scoringCalendar";
import ScoringSnapshot from "./scoringSnapshot";
import ScoringReportDownloads from "./scoringReportDownloads";
import { Stack, Typography } from "@mui/material";
import DetailPage from "../../components/common/detailPage/detailPage";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import ProductSalesCard from "../productSales/productSalesCard";

interface AssessmentsProps {
    tabIndex?: number;
}

export const Assessments: React.FC<AssessmentsProps> = (props) => {
    const [actualTab, setActualTab] = React.useState<number>(0);

    const tablist: [string, number, React.ReactNode][] = [
        ["Scoring Calendar", 0, <ScoringCalendar />],
        ["Scoring Status", 1, <ScoringSnapshot />],
        ["Assessments", 2, <ScoringReportDownloads />],
        ["Product Sales", 3, <ProductSalesCard />],
    ];

    const handleTabChangeRequest = (event: React.SyntheticEvent, newValue: number) => {
        setActualTab(newValue);
    };

    const breadcrumbs = (
        <Stack direction={"row"} spacing={3}>
            <SimpleBreadcrumbs>
                <Typography variant="h6" color="text.primary">
                    Assessments
                </Typography>
            </SimpleBreadcrumbs>
        </Stack>
    );

    return (
        <div>
            <DetailPage
                descriptor={"session"}
                breadcrumbs={breadcrumbs}
                tablist={tablist}
                handleTabChange={handleTabChangeRequest}
                activeTab={actualTab}
            />
        </div>
    );
};

export default Assessments;
