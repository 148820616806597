import * as React from "react";
import { AlertColor, Box, Card, Grid } from "@mui/material";
import { useGetCclEventsMutation } from "../../services/cclTokenedSessionApi";
import CclTextSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextSearchBar";
import { useGetAssetsBySessionKeyQuery } from "../../services/cclTokenedAssetsApi";
import { useGetCclParticipantsByEventKeyQuery } from "../../services/cclTokenedEnterpriseParticipantApi";
import { EventDocument } from "../../services/types/search.service.types";
import ScoringReportDownloadsDataGrid from "./scoringReportDownloadsDataGrid";
import ComponentLoader from "../../components/common/componentLoader";
import { FileDownloadService } from "../../services/fileDownloadService/fileDownloadService";
import CclSearchDefault from "../../components/common/cclSearchDefault";
import { AccessEventSessionDetails } from "../../services/types/accessEventTypes";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import CclAlertSnackbar from "../../components/common/cclAlertSnackbar";

export interface ScoringReportDownloadsProps {}

export const ScoringReportDownloads: React.FC<ScoringReportDownloadsProps> = (props) => {
    const [foundEsKey, setFoundEsKey] = React.useState<number | null>(null);
    const [foundProgramCode, setFoundProgramCode] = React.useState<string>("");
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [
        searchForEvent,
        { isLoading: searching, isSuccess: searchSuccess, isError: searchError },
    ] = useGetCclEventsMutation();
    const {
        data: allAssets,
        isLoading: loadingAssets,
        isSuccess: assetsSuccess,
        isError: assetsError,
    } = useGetAssetsBySessionKeyQuery(foundEsKey?.toString() || "", {
        skip: foundEsKey == null,
    });
    const {
        data: allParticipants,
        isLoading: loadingParticipants,
        isSuccess: participantsSuccess,
        isError: participantsError,
    } = useGetCclParticipantsByEventKeyQuery(foundEsKey?.toString() || "", {
        skip: foundEsKey == null,
    });

    const { logEvent } = useLogAccessEvent();
    const downloadService = new FileDownloadService();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    React.useEffect(() => {
        if (searchSuccess && participantsSuccess && assetsSuccess) {
            if (allAssets?.length === 0 || allParticipants?.length === 0) {
                ShowSnackbarElement("No assessments found for this session.", "warning");
            }
        }
    }, [searchSuccess, participantsSuccess, assetsSuccess, allAssets, allParticipants]);

    React.useEffect(() => {
        if (searchError || assetsError || participantsError) {
            ShowSnackbarElement("Error retrieving session or related assessments.", "error");
        }
    }, [searchError, assetsError, participantsError]);

    const onGridToolbarButtonClick = (eventName: string, selectedIds: (number | string)[]) => {
        switch (eventName) {
            case "download":
                let filename: string = "";
                let aikeys = selectedIds.map((i) => +i);
                if (aikeys.length > 30) {
                    ShowSnackbarElement(
                        "Maximum of 30 files may be downloaded at one time.",
                        "error"
                    );
                    return;
                } else if (aikeys.length > 1) {
                    filename =
                        foundProgramCode !== ""
                            ? `${foundProgramCode}_ScoredAssessments.zip`
                            : "ScoredAssessments.zip";
                } else if (aikeys.length === 1 && allAssets != null && allAssets.length >= 1) {
                    let ast = allAssets.find((a) => a.id === aikeys[0]);
                    if (ast == null) return; // just in case id isn't found
                    filename = ast.name;
                    if (ast.fileExtension !== "") {
                        let fileExt = ast.name.split(".").pop();
                        if (fileExt?.toLowerCase() !== ast.fileExtension.toLowerCase())
                            filename += ast.fileExtension;
                    }
                } else {
                    ShowSnackbarElement("No assessments were selected for download.", "warning");
                    return; // nothing selected
                }
                downloadService
                    .DownloadAssets({ fname: filename, aikeys: aikeys, flatzip: true })
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: foundProgramCode ?? "",
                        };
                        logEvent("ParticipantFilesDownloaded", evtData);
                        ShowSnackbarElement("File download(s) complete.", "success");
                    })
                    .catch((error: { message: string }) => {
                        const msg = error.message ?? "Unknown Error";
                        ShowSnackbarElement(`File download(s) error: ${msg}.`, "error");
                    });
                break;
        }
    };

    const getSessionByKeyword = (keyword: string) => {
        searchForEvent({ keyword: keyword })
            .unwrap()
            .then((results: EventDocument[]) => {
                let matches = results.filter(
                    (e) => e.eventnumber.toString() === keyword || e.projectCode === keyword
                );
                if (matches?.length > 0) {
                    setFoundEsKey(+matches[0].eventnumber);
                    setFoundProgramCode(matches[0].projectCode);
                } else {
                    setFoundEsKey(null);
                    setFoundProgramCode("");
                    ShowSnackbarElement("Session was not found.", "warning");
                }
            })
            .catch((error) => {
                console.log("Error searching for events: " + error);
            });
    };

    return (
        <Box width={1} sx={{ display: "flex", height: "100%", width: "100%" }}>
            <Card
                sx={{
                    width: 1,
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <CclTextSearchBar
                        initialSearchTerm={""}
                        searchLabel="Enter Session ID or Code"
                        executeSearch={getSessionByKeyword}
                    />
                </Box>
                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: 1,
                        width: 1,
                        padding: 2,
                        paddingTop: 0,
                    }}
                >
                    <Box
                        sx={{
                            width: 1,
                            height: 1,
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            position: "relative",
                        }}
                    >
                        {showSnackbar ? (
                            <CclAlertSnackbar
                                open={true}
                                message={snackbarMessage}
                                severity={snackbarSeverity}
                                onClose={() => setShowSnackbar(false)}
                            />
                        ) : null}
                        <div style={{ flexGrow: 1 }}>
                            {searching || loadingAssets || loadingParticipants ? (
                                // search, or loading in progress
                                <ComponentLoader msg={"Searching for event..."} />
                            ) : searchSuccess &&
                              participantsSuccess &&
                              assetsSuccess &&
                              allAssets?.length > 0 &&
                              allParticipants?.length > 0 ? (
                                <React.Fragment>
                                    <ScoringReportDownloadsDataGrid
                                        participants={
                                            allParticipants?.filter(
                                                (p) => p.registrationStatus === "Confirmed"
                                            ) ?? []
                                        }
                                        assets={
                                            allAssets?.filter((a) => a.fileType === "Assessment") ??
                                            []
                                        }
                                        handleEvent={onGridToolbarButtonClick}
                                    />
                                </React.Fragment>
                            ) : (
                                <CclSearchDefault
                                    line1="Start searching for your Session"
                                    line2="Enter PR Code or ID"
                                />
                            )}
                        </div>
                    </Box>
                </Grid>
            </Card>
        </Box>
    );
};

export default ScoringReportDownloads;
